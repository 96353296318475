@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

a {
    color: rgb(188, 40, 149);
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: rgb(218, 218, 218);

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            text-align: center;
        }

        .counter {
            font-weight: 900;
            font-size: 5rem;
            margin: 20px 0px;

            &.more {
                color: rgb(82, 158, 63);
            }

            &.less {
                color: rgb(180, 56, 56);
            }
        }
    }
}